.assessment_config {
  .form__form-group-label {
    color: darkslategray !important;
    font-weight: 700 !important;
  }

  b,
  strong {
    color: darkslategray !important;
  }

  .dropzone__input {
    min-height: 198px;
  }

  .tab-content {
    width: 100%;
  }

  .tabs.tabs--vertical .nav-tabs {
    height: 500px;
    min-width: 110px;
    overflow-y: auto;
  }

  .shorter-text-editor {
    .text-editor {
      width: 100%;
    }
    .text-editor .DraftEditor-root {
      min-height: 90px;
    }
  }

  .question_type_field {
    table {
      table-layout: fixed;

      input {
        text-align: center;
      }

      ::-webkit-input-placeholder {
        text-align: center;
      }

      :-moz-placeholder {
        text-align: center;
      }

      .react-select__control {
        min-height: 32px !important;
      }
    }
    th,
    tr {
      text-align: center;
    }

    .rows-table,
    .columns-table {
      width: 50%;
      display: inline-block;
    }

    .rows-table {
      padding-right: 20px;
    }

    .columns-table {
      padding-left: 20px;
    }

    .radio-btn__label {
      margin-top: 0;
      padding-left: 3px;
    }
  }

  .scaling_rating_question {
    .form__form-group-label {
      width: 100%;
      text-align: center;
    }

    input {
      text-align: center;
    }

    ::-webkit-input-placeholder {
      text-align: center;
    }

    :-moz-placeholder {
      text-align: center;
    }
  }

  .w-fit-content {
    width: fit-content;
  }
}
