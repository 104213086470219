.payment {
  max-width: 740px;
}

.payment__total {
  width: 100%;
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 20px;
}

.payment__credit-name {
  font-size: 10px;
  line-height: 13px;
  margin-top: 3px;
  color: $color-additional;
  text-align: center;
  height: 0;
}

.payment__credit-card {
  @include directify($directions) {
    #{directed('margin-right')}: 80px;
  }

  &:last-child {
    @include directify($directions) {
      #{directed('margin-right')}: 0px;
    }
  }

  @media screen and (max-width: 500px) {
    @include directify($directions) {
      #{directed('margin-right')}: 0px !important;
    }
  }
}

.payment__credit-card_img {
  width: 45px;
}
