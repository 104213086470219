.btn.btn-sm {
  padding: 0px 4px;
  font-size: 14px;
}

.cell-tags button {
  margin: 3px 4px;
}

.actions .btn.icon {
  padding: 3px 10px;
}

.tabs.tabs--vertical .nav-tabs li .nav-link.active a,
.ltr-support .tabs.tabs--vertical a {
  border-bottom: none !important;
}

.competency_add_section {
  max-height: 200px;
  overflow-y: auto;
  width: 100%;

  p {
    display: inline-block;
    margin: 10px;
  }
}

.competency_add_button {
  margin-top: 24px;
}

.competency_tabs {
  .tab-content {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }
  .tab-pane p {
    display: inline-block;
    padding: 10px;
  }

  .tabs__wrap {
    overflow: hidden;
  }
}

.error {
  color: #ff4861 !important;
}

.success {
  color: #4ce1b6 !important;
}
