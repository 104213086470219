form {
  .form-group {
    .table-checkbox {
      width: auto;
      height: auto;
    }
  }
}

.theme-light .wizard__step {
  border: none;
}

.assignment-modal {
  max-width: 455px !important;

  .confirmation-text {
    font-size: 15px;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .form__form-group-label {
    width: fit-content !important;
  }

  .form__form-group-field {
    margin-right: 0;
    width: 165px !important;
  }
}
