.questions-actions {
  button {
    display: block !important;
    margin-bottom: 4px !important;
  }

  .ltr-support .btn {
    margin: 0 auto !important;
  }
}

.question-row {
  .question-text {
    min-width: 250px;
    max-width: 500px;
  }

  .question-header {
    margin-bottom: 0.5em;
    padding: 0.5em 0;
    border-bottom: 1px dotted #eee;

    p {
      font-size: 85%;
      color: rgb(136, 136, 136);
      display: inline-block;
    }

    .badge {
      margin-left: 15px;
    }
  }

  .question-main {
    word-break: break-word;
    white-space: normal;
    font-weight: 500;
  }

  img {
    width: 50%;
    margin: 10px;
    vertical-align: middle;
    border: 0;
  }
}

.dropzone.dropzone--single {
  height: 200px !important;
}

.answer-data {
  width: 100%;
  height: 300px;
  overflow-y: auto;

  .checkbox-btn__checkbox-custom {
    background: #ddd;
    border-color: #ddd;
    border: 1px solid #ddd;
  }
}

.question-extra-html {
  background: lightgray;
  border-radius: 3px;
  padding: 4px 15px;
}
