.add-edit-module-schedule .form__form-group {
  margin-bottom: 5px;
}

//TODO: clean up following
@media screen and (min-width: 480px) {
  .add-edit-module-schedule .form.form--horizontal .form__form-group-label {
    width: 175px !important;
  }
  .add-edit-module-schedule .form.form--horizontal .form__form-group-field {
    width: calc(100% - 175px) !important;
  }
}

.course_logo_image {
  height: 70px;
  width: auto;
}

.module_default_logo {
  svg {
    height: 70px;
    width: auto;
  }
}
