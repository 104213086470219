form {
  .form-group {
    .table-checkbox {
      width: auto;
      height: auto;
    }
  }
}

.lnr-unlink {
  color: #ff4861 !important;
}
