.datastar-card {
  display: flex;
  width: 100%;
  color: #fff;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.selected-panel {
  display: flex;
  width: 100%;
  color: #fff;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  border-bottom: 8px solid #368edb;
}

.card-orange {
  background-color: #ff841a;

  .ds-card-icon {
    background-color: #ce6a12;
  }
}

.card-green {
  background-color: #00a65a;

  .ds-card-icon {
    background-color: #038249;
  }
}

.card-txt {
  padding: 0 15px;

  p {
    color: #fff;
    margin: 0;
  }
}

.extra-counts {
  border-top: 1px solid white;
  padding: 4px 0;
}
