.skill_add_section {
  max-height: 200px;
  overflow-y: auto;
  width: 100%;

  p {
    display: inline-block;
    margin: 10px;
  }
}

.skill_add_button {
  margin-top: 24px;
}
