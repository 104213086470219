.wizard .wizard__form {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 0;
}

.step-one .form__form-group {
  margin-bottom: 10px;
}

.program_logo_img {
  height: 58px;
  width: 132px;
}

.button-placement {
  margin-top: -68px;
  cursor: pointer;
}

.mdl-card {
  width: 50px;
  min-height: 0;
  margin: 0 auto;
}

.card__supporting-text {
  width: 100%;
  padding: 0;
}

.stepper-horizontal-alternative .stepper-step {
  width: 10%;
}

.stepper-horizontal-alternative {
  display: table;
  width: 100%;
  margin: 0 auto;
}

.stepper-horizontal-alternative .stepper-step {
  display: table-cell;
  position: relative;
  padding: 24px 10px;
}

.stepper-horizontal-alternative .stepper-step:active {
  border-radius: 15% / 75%;
}

.stepper-horizontal-alternative .stepper-step:first-child:active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.stepper-horizontal-alternative .stepper-step:last-child:active {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.stepper-circle {
  cursor: default;
}

.stepper-horizontal-alternative .stepper-step:hover .stepper-circle {
  background-color: #757575;
}

.stepper-horizontal-alternative .stepper-step:first-child .stepper-bar-left,
.stepper-horizontal-alternative .stepper-step:last-child .stepper-bar-right {
  display: none;
}

.stepper-horizontal-alternative .stepper-step .stepper-circle {
  width: 24px;
  height: 24px;
  margin: 0 auto;
  background-color: #9e9e9e;
  border-radius: 50%;
  text-align: center;
  line-height: 2em;
  font-size: 12px;
  color: white;
}

.stepper-horizontal-alternative .stepper-step.active-step .stepper-circle {
  background-color: #78b082;
}

.stepper-horizontal-alternative .stepper-step.step-done .stepper-circle:before {
  content: '\2714';
}

.stepper-horizontal-alternative .stepper-step.step-done .stepper-circle *,
.stepper-horizontal-alternative .stepper-step.editable-step .stepper-circle * {
  display: none;
}

.stepper-horizontal-alternative .stepper-step.editable-step .stepper-circle {
  -moz-transform: scaleX(-1);
  /* Gecko */
  -o-transform: scaleX(-1);
  /* Opera */
  -webkit-transform: scaleX(-1);
  /* Webkit */
  transform: scaleX(-1);
  /* Standard */
}

.stepper-horizontal-alternative .stepper-step.editable-step .stepper-circle:before {
  content: '\270E';
}

.stepper-horizontal-alternative .stepper-step .stepper-title {
  margin-top: 16px;
  font-size: 14px;
  font-weight: normal;
  white-space: nowrap;
}

.stepper-horizontal-alternative .stepper-step .stepper-title,
.stepper-horizontal-alternative .stepper-step .stepper-optional {
  text-align: center;
  color: rgba(0, 0, 0, 0.26);
}

.stepper-horizontal-alternative .stepper-step.active-step .stepper-title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.stepper-horizontal-alternative .stepper-step.active-step.step-done .stepper-title,
.stepper-horizontal-alternative .stepper-step.active-step.editable-step .stepper-title {
  font-weight: 300;
}

.stepper-horizontal-alternative .stepper-step .stepper-optional {
  font-size: 12px;
}

.stepper-horizontal-alternative .stepper-step.active-step .stepper-optional {
  color: rgba(0, 0, 0, 0.54);
}

.stepper-horizontal-alternative .stepper-step .stepper-bar-left,
.stepper-horizontal-alternative .stepper-step .stepper-bar-right {
  position: absolute;
  top: 74px;
  height: 1px;
  border-top: 1px solid #bdbdbd;
}

.stepper-horizontal-alternative .stepper-step .stepper-bar-right {
  right: 0;
  left: 50%;
  margin-left: 20px;
}

.stepper-horizontal-alternative .stepper-step .stepper-bar-left {
  left: 0;
  right: 50%;
  margin-right: 20px;
}

.orgprofile__basic-detail {
  height: 100%;
  display: flex;
  float: left;
  cursor: pointer;
  position: relative;
  // border-radius: 0;
  // border: none;
  transition: all 0.3s;
  //min-height: 67px;
}

.orgprofile__basic-icon {
  width: 30px;
  height: 28px;
  border-radius: 50% !important;
  border: none;
  border-radius: 3px 0 0 3px;
  cursor: pointer;
  background-color: #78b082;
  color: white;
  margin: auto;
  margin-left: 0;
  margin-right: 10px;
  padding-left: 5px;
}
.orgprofile__basic-information {
  text-align: left;
  margin-left: 10px;
  margin-top: 10px;
  width: 100%;
}

.orgprofile__basic-header {
  display: flex;
  font-weight: 400;
  // margin-bottom: 10px;
  margin-top: 0;
  line-height: 18px;
  align-items: center;
}

.orgprofile__basic-header-title {
  opacity: 0.6;
}

.orgprofile__basic-body {
  font-weight: 500;
  line-height: 18px;
  font-size: 1rem;
  margin-top: 5px;

  textarea {
    width: 100%;
    //height: 85px;

    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: justify;
  }

  .orgprofile__info-edit-icon {
    visibility: hidden;
  }
}

.orgprofile__basic-body:hover {
  .orgprofile__info-edit-icon {
    visibility: visible;
  }
}
.orgprofile__avatar {
  padding: 0;
}
.orgprofile__avatar-no {
  padding-right: 0;
}
.dashboard__info-title {
  text-align: left;
  margin-left: 35px;
  margin-top: -40px;
}

.bread-crumb-title {
  font-size: smaller;
  font-family: 'Open Sans Condensed', sans-serif;
  color: #a3a3b1;
  font-size: 11px;
  line-height: 1.6;
}

.orgprofile__avatar-img {
  object-fit: cover;
  margin: auto 0;
  border-radius: 12%; // old value - 12%
  height: 120px; // old value - 135px
  width: 120px; // old value - 135px
  // box-shadow: 0 0 8px 2px #e1e1e1;
  //border: 2px solid #cacaca;
}

.applicant_panel_card {
  .panel__body {
    background: #f9f9f9;
  }

  .panel.panel--collapse .panel__title .subhead {
    opacity: 1;
  }
}

.applicant-details-page {
  .tabs.tabs--justify .nav-tabs li {
    width: auto;
  }
}

.card__supporting-text::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.card__supporting-text::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.card__supporting-text::-webkit-scrollbar-thumb {
  background: #d8d7d7;
}

/* Handle on hover */
.card__supporting-text::-webkit-scrollbar-thumb:hover {
  background: #555;
}

textarea::-webkit-scrollbar {
  display: none;
}

.upload-resume {
  background: #f5f6f7;
  border-radius: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
