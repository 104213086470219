.order-history-view-page {
    background: white;
    padding: 30px;
   .container {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 60px;
    flex-wrap: wrap;
   }

p, h1, h2, h3, h4, h5, h6 {
    margin-top: 0px;
    font-weight: 400;
    margin-bottom: 0px;
    font-size: 21px;
}
.right-container {
    width: auto;
    text-align: right;
    margin-top: 0px;
}
.grand-total {
    font-weight: 500;
    font-size: 20px;
    margin-top: 15px;
    width: 100%;
    text-align: right;
}
.preview-back-icon {
    margin-bottom: 10px;
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
  }
  