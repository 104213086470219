.questions {
  font-size: 20px;
  color: #2e485c !important;
  font-weight: 600;
  margin-top: 15px;
}

.qa-item {
  font-size: 13px;
  color: #586d7d;
  margin: 10px 0 10px;
  line-height: 23px;
}

.qa-row {
  cursor: pointer;
}

.answer-item {
  font-size: 17px;
  color: #2e485c !important;
  font-weight: bolder;
  margin-left: -15px;
  white-space: nowrap;
  margin-top: 30px;
}

.qa-answer-logo {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.qa-question-logo {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.qa-icons {
  padding: 4px;
}

.postedby-sub-text {
  color: #525a60 !important;
  font-size: 12px;
  font-weight: 500;
}

.postedby-sub-name {
  font-size: 21px;
  font-weight: 500;
  margin: 0px;
}

.posted-answer {
  white-space: break-spaces;
}

.question-time {
  color: #525a60;
  font-size: 12px;
  font-weight: 500;
  margin: 15px 0;
}

.questions-container {
  background-color: #f5f5f5;
  padding: 0 25px 25px;
}

.answer-time {
  color: #8f9aa0 !important;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.postedby-answer {
  font-size: 16px;
  font-weight: 500;
  color: #525a60;
}

.postedby-answer-email {
  font-size: 11px;
  font-weight: 500;
  color: #525a60;
  display: block;
}

.qa-arrow-icon {
  padding: 0px;
  font-size: 14px;
  margin-top: 30px;
  margin-right: 15px;
  cursor: pointer;
  color: #4e6174 !important;
}

.answer-icon-font {
  font-size: 12px;
  font-weight: normal;
}

.answer-item .delete-icon {
  opacity: 0;
  color: navy;
}

.answer-item:hover .delete-icon {
  opacity: 1;
}
