.wizard .wizard__form {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 0;
}

.step-one .form__form-group {
  margin-bottom: 10px;
}

.program_logo_img {
  height: 58px;
  width: 132px;
}

.program-add-modal {
  max-width: 1000px;

  .ltr-support .modal-dialog--header .modal__body {
    width: 100%;
  }
}

.add-edit-program-modal {
  margin-bottom: 0;
  width: 100%;
  padding-right: 20px !important;
  .form input {
    height: 38px;
  }

  .react-select__control {
    border-color: hsl(0, 0%, 95%) !important;
  }
}

.program-wizard {
  .row {
    width: 100%;
  }

  .lp-error {
    margin-bottom: 10px;
    background-color: #f8d7da;
    padding: 3px;
    border-radius: 3px;
  }
}
.error-message {
  color: red;
  font-weight: bold;
}

.preview-title {
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 30px;
  margin-top: 20px;
}

.preview-description {
  letter-spacing: 0.32px;
  font-size: 12px;
  font-weight: bolder;
  margin: 15px;
}
.preview-description-heading {
  font-weight: 700;
  font-size: 20px;
}

.preview-table-columns {
  white-space: break-spaces;
  max-width: 109px;
}

.preview-image {
  width: 100%;
  position: relative;
}

.preview-bg img {
  height: 280px;
  width: 100%;
  object-fit: cover;
}

.preview-bg {
  max-height: 280px;
  overflow: hidden;
  position: relative;
}

.preview-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview-logo {
  position: absolute;
  z-index: 1;
  left: 1px;
  width: 170px;
  bottom: 0px;
  height: auto;
}
.preview-back-icon {
  margin-bottom: 10px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.logo-image {
  height: 100px;
  width: auto;
}
