.tooltip {
  z-index: 99;

  .tooltip-inner {
    background-color: $color-gray;
    font-size: 12px;
    line-height: 14px;
    padding: 5px 15px;
  }

  &.bs-tooltip-right .arrow::before,
  &.bs-tooltip-auto[x-placement^='right'] .arrow::before {
    border-right-color: $color-gray;
  }

  &.bs-tooltip-top .arrow::before,
  &.bs-tooltip-auto[x-placement^='top'] .arrow::before {
    border-top-color: $color-gray;
  }

  &.bs-tooltip-bottom .arrow::before,
  &.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
    border-bottom-color: $color-gray;
  }

  &.bs-tooltip-left .arrow::before,
  &.bs-tooltip-auto[x-placement^='left'] .arrow::before {
    border-left-color: $color-gray;
  }
}

.card-tooltip {
  @media (min-width: 1426px) {
    -webkit-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media (min-width: 1200px) and (max-width: 1425px) {
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.button-tooltip {
  margin-left: auto !important;
  margin-right: auto !important;
}
