.spinning {
  animation: refresh 1s infinite linear;
  z-index: 10;

  @keyframes refresh {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
