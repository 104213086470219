.organization-main-page {
  .benifits-image {
    width: 45px;
    height: 45px;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 43px;
    color: #2377bb;
  }
  .panel_details {
    width: calc(100% - 45px);
  }
  .gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 10vw);
    grid-gap: 6px;
  }
  .gallery__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
