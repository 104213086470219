.add-edit-learning-path .form__form-group {
  margin-bottom: 0;
}

.lp_logo_image {
  height: 50px;
  padding: 2px;
  width: auto;
}

.lp_default_logo {
  svg {
    height: 70px;
    width: auto;
  }
}

.lp_edit_module_error {
  .form__form-group-error {
    top: -5px !important;
  }
}
