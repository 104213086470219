.wizard .wizard__form {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 0;
}

.step-one .form__form-group {
  margin-bottom: 10px;
}

.program_logo_img {
  height: 58px;
  width: 132px;
}
