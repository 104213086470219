.tp_logo_image {
  height: 150px;
  padding: 2px;
  width: 150px;
}

.tp_default_logo {
  svg {
    height: 70px;
    width: auto;
  }
}
