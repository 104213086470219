.domain_add_section {
  max-height: 200px;
  overflow-y: auto;
  width: 100%;

  p {
    display: inline-block;
    margin: 10px;
  }
}

.domain_add_button {
  margin-top: 24px;
}

.fw-500 {
  font-weight: 500;
}
