.module_logo_image {
  height: 70px;
  width: auto;
}

.module_default_logo {
  svg {
    height: 70px;
    width: auto;
  }
}

.module_image {
  width: 150px;
  object-fit: cover;
}

.cads-ai-add-edit {
  .form__form-group-input-wrap input {
    margin: 8px 0;
  }

  .form.form--horizontal .form__form-group-input-wrap--error-above {
    margin-bottom: 0;
  }
}

.pre-req-view {
  display: inline-block;
  margin: 10px 10px;

  &:first-child {
    margin: 10px 10px;
  }
}
