.chart__option_select {
  align-items: center;
  display: flex;
  margin-right: 10px;

  .option_skills {
    cursor: pointer;
    padding: 5px 10px;
  }
  .option_change_chart {
    cursor: pointer;
    padding: 6px 6px;
  }
  .activeTab {
    background: #dbe1e6;
    border-radius: 20px;
  }
}
