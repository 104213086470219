form {
  .form-group {
    .table-checkbox {
      width: auto;
      height: auto;
    }
  }
}

.bt-config .react-select__value-container {
  display: flex;
  justify-content: center;
}
