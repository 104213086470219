.users-view-page {
  .wizard .wizard__form {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .step-one .form__form-group {
    margin-bottom: 10px;
  }

  .tabs__end-btns {
    position: absolute;
    top: 0px;
  }

  .tabs__opportunities {
    padding: 0px !important;
  }

  .button-placement {
    cursor: pointer;
  }

  .table-wrapper {
    max-width: 150px;
    margin: 20px auto;
  }

  .font-color-for-common {
    color: #2e485c;
  }

  .empprofile__trophy-verified-skills {
    width: 30px;
    height: 28px;
    border-radius: 50% !important;
    border: none;
    border-radius: 3px 0 0 3px;
    cursor: pointer;
    margin: auto;
    margin-left: 0px;
    margin-right: 10px;
    padding-left: 5px;
    background-color: #fff1dd;
    color: red;
  }

  .empprofile__trophy-other-skills {
    width: 30px;
    height: 28px;
    border-radius: 50% !important;
    border: none;
    border-radius: 3px 0 0 3px;
    cursor: pointer;
    margin: auto;
    margin-left: 0px;
    margin-right: 10px;
    padding-left: 5px;
    background-color: #e5faef;
    color: #0cd370;
  }

  .skill-level-component {
    display: flex;
    min-height: 30px;
    margin: 10px 0 0 7px;
    background: #f5f5f5;
    border-radius: 50px;
    height: auto;
    width: fit-content;
    padding: 9px 20px 8px 17px;
  }

  .skill-name-i-have {
    color: #f5f5f5;
    background-color: #525a60;
    padding: 0 20px;
    margin-left: 10px;
    border-radius: 100px;
    font-size: medium;
  }

  .tabs-sub {
    padding: 1rem 1rem !important;
    cursor: pointer;
    .upload-sub-tabs .nav-link {
      font-size: smaller;
      text-align: center;
      padding: 8px !important;
      color: #525a60 !important;
      font-weight: normal !important;
      width: 100%;
      border-radius: 40px !important;
      border-bottom: none !important;
      background: #f5f5f5 !important;
    }
    .upload-sub-tabs .nav-link.active {
      text-align: center;
      padding: 9px !important;
      color: white !important;
      font-weight: normal !important;
      width: 100%;
      border-radius: 40px !important;
      border-bottom: none !important;
      background: #525a60 !important;
    }
  }

  .skills-card {
    border: 1px solid lightgray;
    border-radius: 4px;
  }

  .skills-wrapper {
    padding: 20px;
  }

  .ss-high {
    height: 150px;
    width: 150px;
  }

  .ss-medium {
    height: 120px;
    width: 120px;
    margin-top: 15px;
  }

  .ss-low {
    height: 90px;
    width: 90px;
    margin-top: 30px;
  }
  .smart-skill-card {
    justify-content: center;
  }

  .smart-skill-container {
    background-color: #fafafa !important;
  }

  .smart-skill-text {
    float: bottom;
    margin: auto;
    //margin-top: 10px;
    word-break: break-word;
  }

  .smart-skill-circle-center {
    height: 150px;
    width: 150px;
    justify-content: center;
    align-content: center;
    display: flex;
    margin: auto;
    margin-bottom: 20px;
  }

  .smart-skill-circle {
    text-align: center;
    border-radius: 50%;
    background-color: #4cbdec;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //margin: auto;

    .score {
      color: white;
      font-size: 16px;
      font-weight: 700;
      flex: 0 1 100%;
      align-self: flex-end;
    }

    .level {
      color: black;
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
      flex: 0 1 100%;
    }
  }

  .behavioral-tab {
    margin-top: 20px;
    color: '#1D3B55';
  }

  .competency-tab {
    margin-top: 20px;
    color: '#1D3B55';
  }

  .ss-detail-circle-center {
    justify-content: center;
    align-content: center;
    display: flex;
    margin: auto;
    //width: 90px;
    height: 90px;
    width: 100%;
  }

  .ss-detailed-high {
    height: 90px;
    width: 90px;
    margin: 0;
  }

  .ss-detailed-medium {
    height: 80px;
    width: 80px;
    margin: 0;
  }

  .ss-detailed-low {
    height: 70px;
    width: 70px;
    margin: 0;
  }

  .container-smarts {
    height: 100%;
    width: 100%;
    margin: 0 !important;
    text-align: center;
  }

  .div-container-smart-skill {
    width: 50%;
    height: 50%;
    float: left;
    overflow: hidden;
  }

  .smart-skill-text-detailed {
    //width: 190px;
    margin: auto;
    //margin-top: 10px;
    word-break: break-word;
    font-size: 8px;
    font-weight: 600;
    white-space: nowrap;
  }

  .card-deck .card {
    margin-right: 10px;
    margin-left: 10px;
  }

  .selected-ss {
    border: 3px solid #3a414b;
  }

  .learning-skills {
    font-size: 18px;
  }

  .progress-circle {
    border: 1px solid #c0c8ce;
    border-radius: 8px;
    padding: 16px 24px 24px;
    flex: 1;
  }

  .progress-title {
    display: flex;
    align-items: center;
    gap: 40px;
    color: #2e485c;
    font-size: 16px;
    font-weight: 500;
  }

  .donut-chart-block {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .donut-chart {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto 2rem;
    border-radius: 100%;
  }

  .part1 {
    transform: rotate(0deg);
  }

  .part2 {
    transform: rotate(100deg);
  }

  .part3 {
    transform: rotate(250deg);
  }

  .part1 .circle {
    background-color: #1376bb;
    transform: rotate(100deg);
  }

  .part2 .circle {
    background-color: #05944f;
    transform: rotate(150deg);
  }

  .part3 .circle {
    background-color: #d5dade;
    transform: rotate(111deg);
  }

  .portion-block {
    border-radius: 50%;
    clip: rect(0px, 200px, 200px, 100px);
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .circle {
    border-radius: 50%;
    clip: rect(0px, 100px, 200px, 0px);
    height: 100%;
    position: absolute;
    background-color: #1376bb;
    width: 100%;
  }

  p.center {
    background: White;
    position: absolute;
    text-align: center;
    font-size: 28px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 175px;
    height: 175px;
    margin: auto;
    border-radius: 50%;
    line-height: 35px;
    padding: 25% 0 0;
    display: flex;
    flex-direction: column;
    color: #586d7d;
    font-weight: 500;
  }

  .progress-circle .progress-subtitle {
    color: #586d7d;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
  }

  .progress-circle .progress-subtitle > .bullet {
    width: 12px;
    height: 12px;
    margin-right: 16px;
    background-color: #d5dade;
    display: inline-block;
    border-radius: 50%;
  }

  .progress-circle .progress-subtitle > .bullet.started {
    background-color: #1376bb;
  }
  .progress-circle .progress-subtitle > .bullet.completed {
    background-color: #05944f;
  }

  .grid {
    display: flex;
    gap: 24px;
  }

  .grid-item {
    width: 25%;
    cursor: pointer;
  }

  .learn-item {
    border: 1px solid #d5dade;
    border-radius: 8px;
    padding: 24px;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .learn-item .image {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f6f7;
  }

  .sub-title {
    color: #2e485c;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.015em;
  }

  .learn-item .item-title {
    margin: 16px 0 8px 0;
  }

  .learn-item .item-desc {
    color: #586d7d;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .skills-learn-wrapper .skill-card {
    margin-bottom: 24px;
  }

  .learn-item .next {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .learn-item .next .btn {
    font-size: 14px;
    padding: 9px 16px;
    background-color: #f5f6f7;
    color: #1376bb !important;
    border-radius: 64px;
    font-weight: 600;
    display: flex;
    margin: 10px;
    align-items: center;
    gap: 5px;
  }

  .load-more {
    color: #21cfba;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }
  .img_thumb {
    display: flex;
    align-items: center;
    max-width: 60px;
    margin-right: 20px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #efebeb;
  }

  .panel_details {
    max-width: calc(100% - 120px);
  }
}
